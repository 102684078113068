.Container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Heading {
  background-color: #191919;
  color: white;
  text-align: center;
  padding: 0 0 150px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
}

.Heading h1 {
  font-size: 3rem;
}

.Bio {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-areas:
    "Intro Intro"
    "Over Over"
    "Education Education"
    "Skills Skills"
    "Languages Languages"
    "Other Other";
  padding: 50px 100px 150px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
}

.Intro {
  grid-area: Intro;
  padding-right: 50px;
  /* width: 25vw; */
}

.Intro h1 {
  font-size: 3rem;
  letter-spacing: 0.1rem;
}

.Intro p {
  font-size: 2rem;
  font-weight: 400;
}

.Over {
  grid-area: Over;
  background-color: #f5f5f5;
  padding: 50px;
  display: flex;
  justify-self: center;
  width: fit-content;
  height: fit-content;
  margin-bottom: 150px;
}

.Over img {
  height: 350px;
  width: 350px;
  margin: 0 0 -100px;
  position: relative;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 1);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 1);
  border-radius: 3px;
}

.Education {
  grid-area: Education;
  padding-bottom: 10px;
}

.Title h1 {
  padding: 8px 10px;
  color: rgb(97, 97, 97);
  border-bottom: 5px rgb(161, 161, 161) solid;
  line-height: 4rem;
}

.Title h3 {
  font-size: 2rem;
  margin: 0 0 0px;
  color: rgb(16, 16, 88);
}

.School {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr;
  font-size: 1.2rem;
  justify-content: space-between;
}

.School span {
  font-weight: 500;
  padding: 8px 10px;
}

.Skills {
  grid-area: Skills;
  display: flex;
  flex-flow: column;
}

.Languages {
  grid-area: Languages;
  display: flex;
  flex-flow: column;
}

.Other {
  grid-area: Other;
  display: flex;
  flex-flow: column;
}

.Box {
  grid-area: Box;
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Skill {
  /* grid-area: Skill; */
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  margin: 10px 10px;
}

.Skill img {
  height: 100px;
  width: 100px;
  padding: 10px 10px;
}

.Skill img:hover{
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Skill h1 {
  font-size: 1.5rem;
  padding: 5px 10px;
}

@media screen and (max-width: 480px),
  (max-height: 480px) and (orientation: landscape) {
  .Heading {
    padding: 0 0 50px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px));
  }

  .Heading h1 {
    font-size: 3rem;
  }

  .Bio {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 50px 10px 50px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px));
  }

  .Intro {
    padding: 0 20px;
  }

  .Intro h1 {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
  }

  .Intro p {
    font-size: 1rem;
    font-weight: 400;
  }

  .Over {
    background-color: #f5f5f5;
    padding: 20px 20px;
    margin-bottom: 50px;
  }

  .Over img {
    height: 250px;
    width: 200px;
    margin: 0 0 -50px;
  }

  .Title h1 {
    padding: 8px 10px;
    color: rgb(97, 97, 97);
    border-bottom: 5px rgb(161, 161, 161) solid;
    line-height: 2rem;
  }

  .Title h3 {
    font-size: 1rem;
    margin: 0 0 0px;
    color: rgb(16, 16, 88);
  }

  .School {
    display: grid;
    grid-template-columns: 2fr 1.5fr 1fr;
    font-size: 1rem;
    justify-content: space-between;
  }

  .Box {
    padding: 0 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
  }

  .Skills{
    width: 100%;
    text-align: center;
  }

  .Languages{
    text-align: center;
    width: 100%;
  }

  .Other{
    text-align: center;
    width: 100%;
  }

  .Skill {
    justify-self: center;
  }

  .Skill h1 {
    font-size: 1.2rem;
  }
}

.Project {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 50px;
}

.Desc {
  padding: 0 50px;
  width: 25vw;
}

.Desc h1 {
  color: rgb(97, 97, 97);
  border-bottom: 5px rgb(161, 161, 161) solid;
  line-height: 4rem;
}

.Desc p {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-style: italic;
}

.Desc a{
  font-weight: 500;
  /* text-decoration: none; */
  padding: 0 10px 0 0;
  color: blue;
}


.PrOver {
  background-color: white;
  padding: 50px;
  margin-bottom: 50px;
}


.PrOver img {
  height: 400px;
  width: 600px;
  margin: 0 0 -100px;
  position: relative;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 1);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 1);
  border-radius: 3px;
}

.PrOver img:hover {  
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Flutter {
  background-color: white;
  padding: 25px;
  margin-bottom: 50px;
}

.Flutter img {
  height: 500px;
  width: 250px;
  margin: 0 0 -50px;
  position: relative;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 1);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 1);
  border-radius: 3px;
}

.Flutter img :hover{
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media screen and (max-width: 480px),
  (max-height: 480px) and (orientation: landscape) {
  .Project {
    justify-content: space-evenly;
    flex-flow: column;
    align-items: center;
    padding: 25px 20px;
  }

  .Desc {
    padding: 0 25px;
    width: 90%;
  }

  .Desc h1 {
    margin: 0;
    line-height: 2rem;
  }

  .Desc p {
    font-size: 1.2rem;
  }

  .PrOver {
    padding: 20px 15px 80px;
    margin-bottom: 50px;
  }

  .PrOver img {
    height: 200px;
    width: 300px;
  }

  .Flutter {
    background-color: #f1f1f1;
    padding: 25px;
    margin-bottom: 50px;
  }

  .Flutter img {
    height: 400px;
    width: 200px;
    margin: 0 0 -50px;
  }
}

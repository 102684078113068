.Container {
  background-color: #121316;
  padding: 100px 100px;
  display: flex;
  flex: 1;
  justify-content: center;
  color: white;
  clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
}

.Touch {
  padding-right: 50px;
  width: 35vw;
}

.Touch h1 {
  letter-spacing: 0;
  font-size: 2rem;
  color: orange;
}

.Touch p {
  font-size: 1.5rem;
  font-style: italic;
}

.Links {
  color: white;
  display: flex;
  flex-flow: column;
  /* text-align: center; */
  justify-content: space-around;
  /* align-items: center; */
  padding: 1%;
}

.Element {
  display: flex;
  align-items: center;
}

.Element a {
  font-size: 1.2rem;
  text-decoration: none;
  padding-left: 10px;
  color: rgb(255, 255, 255);
}

.Element a:hover {
  color: orange;
}

@media screen and (max-width: 480px),
  (max-height: 480px) and (orientation: landscape) {
  .Container {
    padding: 100px 20px 50px;
    flex-flow: column;
    justify-content: center;
    clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 100%);
  }

  .Touch {
    padding-right: 0;
    width: 100%;
  }

  /* icon size */
  .link {
    transform: scale(0.8);
  }
}

.Container {
  flex: 1;
  display: flex;
  flex-flow: column;
  /* background-color: yellow; */
  justify-content: space-between;
}

.Footer {
  background-color: #121316;
  text-align: center;
  color: white;
  letter-spacing: 0.15rem;
}

.SideBar {
  display: none;
}
.Desktop {
  display: none;
}
.SideBar {
  display: block;
  margin-bottom: 50px;
}
@media screen and (max-width: 480px),
  (max-height: 480px) and (orientation: landscape) {
  .Desktop {
    display: none;
  }
  .SideBar {
    display: block;
    margin-bottom: 50px;
  }
}

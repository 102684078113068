.nav {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 50px;
  flex: 1;
  padding-bottom: 50px;
  background-color: #191919;
  align-items: center;
}

.Logo {
  font-size: 1.5rem;
  margin-left: 50px;
  /* letter-spacing: 0.2rem; */
  text-decoration: none;
  color: orange;
}

.Logo h2 {
  margin-bottom: 1px;
}

.LogoActive {
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-bottom: 4px solid orange;
}

.navLinks {
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.navItem {
  font-size: 1rem;
  text-decoration: none;
  margin: 0 25px;
  color: rgb(255, 255, 255);
}

.navItem h2 {
  font-weight: 400;
  margin-bottom: 1px;
}

.navItem :hover,
.Logo:hover {
  color: orange;
}

.navActive {
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-bottom: 4px solid orange;
  /* cursor: default; */
}

.Sidebar {
  display: flex;
  flex: 1;
  height: fit-content;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 100;
  justify-content: space-between;
  background-color: #191919;
  color: white;
  align-items: baseline;
}

.Sidebar .Logo {
  font-size: 0.8rem;
  margin-left: 15px;
}

.Menu h1 {
  margin: 0;
  color: white;
  padding: 0;
}

.MenuLink {
  text-decoration: none;
  color: black;
}
